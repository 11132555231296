import React from "react"
import Styles from "./css/ty.module.scss"
import tyData from "./data/ty.json"
import {isChrome,isFirefox,isIeEdge,isSafari,getBrowser} from "@tightrope/lpscripts/browserdetect"
import FooterLinksSingle from "@tightrope/footerlinks/footerlinks_single.js"

class ty extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: tyData
    }
  }

showBrowsers(browsers){
  let display = false;
  if(getBrowser() === 'ie' && browsers.includes('ie')){
    display=true;
  }
  if(getBrowser() === 'firefox' && browsers.includes('firefox')){
    display=true;
  }
  if(getBrowser() === 'chrome' && browsers.includes('chrome')){
    display=true;
  }
  if(getBrowser() === 'edge' && browsers.includes('edge')){
    display=true;
  }
  if (getBrowser() === "safari" && browsers.includes("safari")) {
    display = true;
  }
  if(getBrowser() === 'other' && browsers.includes('other')){
    display=true;
  }
  if(display)
    this.setState({display: 'block'});
  else
    this.setState({display: 'none'});
}

componentDidMount() {
  if(this.props.browsers){
    this.showBrowsers(this.props.browsers);
  }
  else{
    this.setState({display: 'block'});
  }
  if(this.props.data){
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();
    this.state.isSafari = isSafari();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (isSafari()) {
      const browserData = Object.assign(this.state.data, this.state.data.safari);
      this.setState({ data: browserData });
    }
    if (isIeEdge()) {
      const browserData = Object.assign(this.state.data, this.state.data.edge);
      this.setState({ data: browserData });
    }


    });
  }
}
render() {
    return (
    <div className = {Styles.tyPage}>
      <section>
        <header>
        <img src = {this.state.data.logo} alt="Flair Wallpapers"></img>
        </header>
        <h1>{this.state.data.headerTitle}</h1>
        <video className={Styles.howToVideo} loop autoPlay muted><source src={this.state.data.howToVideo}></source></video>
        <div className={Styles.instructions}>
        <ol className = {Styles.instructionList}>
        <h2>{this.state.data.instructionsTitle1}</h2>
              {this.state.data.aiInstructions != undefined ? this.state.data.aiInstructions.map((bullet, i) => {
                  return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                }) : null}
        </ol>
        <ol className = {Styles.instructionList}>
        <h2>{this.state.data.instructionsTitle2}</h2>
              {this.state.data.hdInstructions != undefined ? this.state.data.hdInstructions.map((bullet, i) => {
                  return <li key={i} dangerouslySetInnerHTML={{ __html: bullet}}></li>
                }) : null}
        </ol>
        </div>
        <h3>{this.state.data.headerSubTitle}</h3>
        <div className={Styles.otherProducts}>     
        <a href="https://easyprint.app?utm_source=easyview.app" target="_blank" rel="noopener noreferrer"><img src = {this.state.data.easyPrintLogo} alt="Easy Print" className = {Styles.promoLogos}></img></a>
        <a href="https://easysave.app?utm_source=easyview.app" target="_blank" rel="noopener noreferrer"><img src = {this.state.data.easySaveLogo} alt="Easy Save" className = {Styles.promoLogos}></img></a>  
        <a href="https://geteasyshare.app?utm_source=easyview.app" target="_blank" rel="noopener noreferrer"><img src = {this.state.data.easyShareLogo} alt="Easy Share" className = {Styles.promoLogos}></img></a>
        <a href="https://fabtab.org?utm_source=easyview.app" target="_blank" rel="noopener noreferrer"><img src = {this.state.data.fabTabLogo} alt="Fab Tab" className = {Styles.promoLogos}></img></a>
        </div>  
      </section>
    </div>

    )
  }
}

export default ty
